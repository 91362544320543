@import './variable.scss';
.text{
  &-primary{color: $primary;}
  &-secondary{color: $secondary;}
  &-accent{color: $accent;}
  &-error{color: $error;}
  &-success{color: $success;}
  &-info{color: $info;}
  &-white{color: $white;}
}
[bgColor="primary"]{background-color: $primary!important;}
[bgColor="secondary"]{background-color: $secondary!important;}
[bgColor="accent"]{background-color: $accent!important;}
.MuiButton {
  $Mui: &;
  &Base-root {
    &#{$Mui}-root {padding: 0.625rem 0.5rem;border-radius: 0.625rem;
      #{$Mui}{
        &-startIcon{width: 1.25rem;height: 1.25rem;}
        &-endIcon{width: 1.25rem;height: 1.25rem;}
      }
      &#{$Mui}{
        &-sizeSmall{line-height: 1.5;font-size: 0.875rem;
          &>.MuiSvgIcon-root {width: 0.75rem;height: 1.25rem;font-size: 0.75rem;}
          #{$Mui}{
            &-startIcon{width: 0.875rem;height: 0.875rem;}
            &-endIcon{width: 0.875rem;height: 0.875rem;}
          }
          &#{$Mui}-box{width: 1.875rem;height: 1.875rem;}
        }
        &-box{width: 2.5rem;height: 2.5rem;padding: 0;min-width: 0;display: flex;align-items: center;}
      }
      &>.MuiSvgIcon-root {width: 1.25rem;height: 2.5rem;font-size: 1rem;
        &.loader{width: 2.5rem;margin-block: -0.5rem -0.25rem;height: 3.25rem;}
      }

    }
    &#{$Mui}-contained {box-shadow: none;
      &Primary {border: $white solid 0.0625rem;box-shadow: 0px 0px 23px 0px rgba($primary, 0.2);}
      &.accent{background-color : $accent;}
      &.error{background-color : $error;}
      &.success{background-color : $success;}
      &.info{background-color : $info;}
      &.white{background-color : $white;}
    }
    &#{$Mui}-outlined {box-shadow: none;
      #{$Mui}-endIcon {
        svg {
          path{fill: $accent;}
        }
      }
      &.accentFill{background-color:  lighten($color: $info, $amount: 3.5%);border: rgba($black, 0.25) dashed 0.0625rem;
      }
    }
  }
}
.MuiForm{
  
  &Control-root{
    .MuiFormLabel{
      &-root{margin-inline-start: 0.625rem;color: rgba($black, 0.5);
        &.MuiInputLabel-shrink,&.Mui-focused,&.MuiFormLabel-filled{transform: translate(14px, -9px) scale(1);color: rgba($black, 0.5);}
      }
    }
    .MuiInput{
      &Base-root{background-color: $white;
        &.MuiOutlinedInput-root{border-radius: 0.625rem;
          //.MuiInputBase-input{padding-inline-start: 1.25rem;}
          .MuiInputAdornment-root{
            .MuiTypography-root {font-weight: 500;font-size: 1.25rem; color: rgba($black, 0.3);}
          }
          .MuiOutlinedInput-notchedOutline{border-color:rgba($black, 0.2);border-width: 0.0625rem; border-radius: 0.625rem;
            legend{margin-inline-start: 0.625rem;font-size: 0.875rem;}
          }
          &.Mui-focused{
            .MuiOutlinedInput-notchedOutline{border-color:rgba($primary, 0.5);}
          }
        }
      }
    }
    &.textCenter{
      .MuiInput{
        &Base-root{
          &.MuiOutlinedInput-root{
            .MuiInputBase-input{text-align: center;}
          }
        }
      }
    }
    &.transparent{
      .MuiInput{
        &Base-root{background-color: transparent;}
      }
    }
    &.colorWhite{
      .MuiInput{
        &Base-root{color: $white;
          &.MuiOutlinedInput-root{
            .MuiOutlinedInput-notchedOutline{border-color:rgba($white, 0.2);}
            &.Mui-focused{
              .MuiOutlinedInput-notchedOutline{border-color:rgba($white, 0.5);}
            }
          }
        }
      }
    }
  }
  &ControlLabel{
    &-root{
      .MuiTypography-root{font-size: 0.75rem!important  ;line-height: 1.25;color: lighten($color: $black, $amount: 30%);}
    }
  }
}
.MuiInputBase{
  $Mui: &;
  &-root{
    &.transparent{
      &::before,&::after{display: none;}
      #{$Mui}-input{font-size: 1.5rem;font-weight: 600;
        &::placeholder-shown,&::placeholder{font-size: 1rem;}
      }
    }
    &[textalign="center"]{
      #{$Mui}-input{text-align: center;}
    }
  }
}
.MuiCard{
  $Mui: &;
  &-root{
    &.MuiPaper{
      &-elevation2{border-radius: 1.25rem;border:lighten($color: $accent, $amount: 65%) solid 0.0625rem;box-shadow: 0 0 1.25rem 0 rgba($black, 0.06);
        #{$Mui}Content-root{padding: 1.25rem 0.9375rem;}
      }
      &-elevation3{border-radius: 0.9375rem;box-shadow: 0 0 1.25rem 0 rgba($black, 0.05);
        #{$Mui}Content-root{padding:0.9375rem;}
      }
      &-elevation4{border-radius: 1.25rem;box-shadow: 0 0 1.25rem 0 rgba($black, 0.05);border: 1px solid rgba($black, 0.075);
        #{$Mui}Content-root{padding: 1.25rem ;}
      }  
      &-elevation5{border-radius: 1.25rem;box-shadow: 0 0 1.25rem 0 rgba($black, 0.05);border: 0.0625rem solid rgba($black, 0.1);
        #{$Mui}Content-root{padding: 1.25rem ;}
      }  
    }
  }
}
.MuiDialog{
  $Mui: &;
  &-container{
    &>.MuiPaper{
      &-root{border-radius: 1.25rem; margin: 1.25rem;width: 100%;
        &#{$Mui}-paperFullScreen{border-radius: 0;margin: 0;}
      }
    }
    #{$Mui}Title-root{font-size: 1rem;font-weight: 600;margin: 0;display: flex;align-items: center;gap:0.625rem;padding:1.5rem;
      .MuiSvgIcon-root{width: 1.25rem;height: 1.25rem;display: flex;align-items: center;}
    }
  }
}
.MuiAvatar{
  &-root{
    &[color="primary"]{background-color: $primary!important;}
    &[color="sky"]{background-color: lighten($color: $sky, $amount: 30%) !important;}
    .MuiSvgIcon-root {width: 100%;height: 100%;}
  }
}
.idvc {
  .uploader-wrap {
    .uploader{border-radius: 1.25rem;background-color: lighten($color: desaturate($color: $secondary, $amount: 40%), $amount: 70%) ;border-color: darken($color: $white, $amount: 15%)}
    .uploader__icon-camera{background-color: transparent;margin-block-end: 2.5rem;
      svg{width: 3.125rem;height: 3.125rem;
        path,use{fill: $secondary;stroke:$secondary; stroke-width: 1.5rem;}
      }
    }
  }
  .video-wrap__btns--two-btn{justify-content: 'center';}
  .video-wrap__btns {
    .el-button{padding-block: 0.625rem;}
  }
  .idvc__reset-all{text-align: center;justify-content: center;}
}
.el-loading-spinner{display: flex;align-items: center;justify-content: center;}
.rightNav {
  .Mui {

    &Avatar-root {background-color: $primary;color: $white;font-size: 1.25rem;font-weight: 500;border: rgba($primary, 0.2) solid 0.25rem;background-clip: padding-box;width: 3.75rem;height: 3.75rem;margin: 0 auto;}
    &Drawer {
      &-paper {padding: 1.25rem;min-width: 70%;max-width: 70%;}
    }

    &List-root {margin-inline: -0.5rem;width: calc(100% + 1rem);
      .MuiDivider-root {margin-block: 0.125rem;}
    }
    &ListItem {
      // &Button-root{ border-block-end: rgba($black, 0.1) solid 0.0625rem;}
      &Icon-root {
        width: 1.25rem;
        height: 1.25rem;
        min-width: 0;
        margin-inline-end: 1.25rem;
      }

      &Text-root {
        .MuiTypography-root {color: $black;}
      }
    }

  }
}

.MuiDrawer{
  $drawer: &;
  &-root{
    &.grayBg{
      #{$drawer}-paper{background-color: darken($color: $white, $amount: 5%);}
    }
  }
  &-paper{
    &#{$drawer}-paperAnchorBottom{border-radius: 1.25rem 1.25rem 0 0;}

  }
}
.swiper{
  $swiper: &;
  &.infoSlider{overflow: visible;
    #{$swiper}-pagination{position: static;margin-block-start: 0.25rem;}
  }
}
.swiper-pagination{
  $swiper: &;

  &-bullet{
    &#{$swiper}-bullet-active{transform: scale(1.25); transition: all .3s; background-color: $secondary;}
  }
}

// .hide{opacity: 0;pointer-events: none;transition: all .5s;top: ;}
