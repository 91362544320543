@import './variable.scss';
// $primary:#0044a7;
.landing{
  &Wrapper{width: 100%;margin: 0 auto;position: relative;min-height: 100%;
    *{font-family: $fontFace;}
    .MuiContainer-maxWidthXl{padding-inline: 4.375rem;}

    .MuiTypography{

      &-h2 {font-size:3.75rem;text-transform: capitalize;
        &.titel{font-size: 4.375rem;line-height: 1.25;font-weight: 600;}
      }
      &-h3 {font-size:3.125rem;font-weight: 600;}
      &-h4 {font-size:1.875rem;font-weight: 600;}
      &-h5 {font-size:1.625rem;font-weight: 500;}
      &-body1{font-size: 1.25rem;font-weight: 400;color: $black;}
      &-body2{font-size: 0.875rem;}
    }
    // h1,h2,h3 {font-family: $fontHeading;color:$black;margin-block-end: 0.625rem;}
    .contentBox{color: rgba($black, 0.6);border-inline-start: rgba($primary,0.2) solid 0.0625rem;padding-inline-start: 3.75rem;
      p{margin-block-end: 1.25rem;}
    }
  }
  &Container{max-width: 76.25rem;width: 100%;margin: 0 auto; padding-block: 5rem;}
}
.sideNav{padding: 0;margin: 0;list-style: none;position: sticky; top: 7.5rem;
  li{font-size: 1.25rem;
    &+li{margin-block-start: 0.75rem;}
    a{color: lighten($color: $black, $amount: 25%);
      &.active{color: $accent;}
    }
  }
}
.text-gradient {
  background: linear-gradient(to right, #003DD9 10%, #003255 150%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.chips{font-size: 0.847rem;font-weight: 500;padding: 0.5rem 1rem ;background-color: #edfedf;border-radius: 1.25rem}

@media screen and (max-width: 1370px) {
  .landing{
    &Wrapper{
      .MuiContainer-maxWidthXl{padding-inline: 2.5rem;}
      .MuiTypography{
        &-h2 {font-size:3.75rem;text-transform: capitalize;
          &.titel{font-size: 3.75rem;line-height: 1.25;font-weight: 600;}
        }
        &-h3 {font-size:3.125rem;font-weight: 600;}
        &-h4 {font-size:1.875rem;font-weight: 600;}
        &-h5 {font-size:1.625rem;font-weight: 500;}
        &-body1{font-size:0.9375rem;}
        &-body2{font-size: 0.875rem;}
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .landing{
    &Wrapper{
      .MuiTypography{
        &-h2 {font-size:3.75rem;text-transform: capitalize;
          &.titel{font-size: 4.375rem;line-height: 1.25;font-weight: 600;}
        }
        &-h3 {font-size:3.125rem;font-weight: 600;}
        &-h4 {font-size:1.875rem;font-weight: 600;}
        &-h5 {font-size:1.625rem;font-weight: 500;}
        &-body1{font-size:0.9375rem;}
        &-body2{font-size: 0.875rem;}
      }
      // h1,h2,h3 {font-family: $fontHeading;color:$black;margin-block-end: 0.625rem;}
      .contentBox{border-inline-start:none;padding: 1.25rem;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .landing{
    &Wrapper{
      .MuiContainer-maxWidthXl{padding-inline: 0rem !important;}
      .MuiTypography{
        &-h2 {font-size:3.75rem;text-transform: capitalize;
          &.titel{font-size: 1.625rem;line-height: 1.25;font-weight: 600;}
        }
        &-h3 {font-size:3.125rem;font-weight: 600;}
        &-h4 {font-size:1.875rem;font-weight: 600;}
        &-h5 {font-size:1.625rem;font-weight: 500;}
        &-body1{font-size:0.75rem;}
        &-body2{font-size: 0.875rem;}
      }
    }
  }
}
