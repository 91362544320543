@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Chivo:wght@400;500;600;700&display=swap");

$primary:#011E46;
$secondary:#006794;
$accent:#00365B;
$info:#D3F1FF;

$sky: #0B99FF;
$black:#000;
$white:#fff;
$error:#f44336;
$success:#008626;
$fontFace:"Poppins", sans-serif;
$fontHeading: 'Open Sans', sans-serif; 
$fontInter: 'Inter', sans-serif;
$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 576px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 1000px,
  // Extra large screen / wide desktop
  xl: 1200px
);
/*
.nbcuprocessing{
  $secondary:#756EFB;
}*/
