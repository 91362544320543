@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'scss/variable.scss';

[type='text']:focus, input:where(:not([type])):focus, [type='email']:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus {
	outline: none;
	outline: 0px !important;
	outline-offset: 0px !important;
	box-shadow: none !important;
}
*{outline: none; text-decoration: none;box-sizing: border-box;-webkit-text-size-adjust: 100%;text-size-adjust: none}
*:hover{text-decoration: none;}
html{height: 100%;width: 100%; padding: 0; margin: 0;scroll-behavior: smooth;}
body {height: 100%; min-height: 100%;width: 100%; padding: 0; margin: 0;font-size: 16px;line-height: 1.5;font-family:$fontFace;background-color:$white;overflow-y: auto;scroll-behavior: smooth;}
input:-internal-autofill-selected {background-color: transparent!important;}
a{text-decoration: none;cursor: pointer;}
svg{width: 100%;height: 100%;}
img{width: 100%;height: 100%; object-fit:contain}
input::placeholder{font-size: 0.875rem; color: lighten($color: $black, $amount: 50%);}
::-webkit-scrollbar {height: 0.375rem; width: 0.375rem;border-radius: 0.625rem;opacity: 0;
	&-track {background: rgba($black,0);border-radius: 0.625rem;}
	&-thumb {background: rgba($black,0.2);border-radius: 0.625rem;}
	&-corner { background: transparent; }
}
:hover{
	&::-webkit-scrollbar{
		&-track {background: rgba($black,0.1);}
		&-thumb {background: rgba($black,0.3);}
	}
}
.clear,[class^='clear-'], [class*=' clear-']{clear: both;font-size: 0;line-height: 0;}
.clear{
	&10{height: 0.625rem;}
	&20{height: 1.25rem;}
	&30{height: 1.875rem;}
	&40{height: 2.5rem;}
	&60{height: 3.75rem;}
}

#vc-message-box{display: none!important;}
.idvc .main-container{padding-top: 0 !important; height: 50vh}
.videoCapturingEl{height: 50vh !important;}
.mainContainer{margin:0 auto; min-height: 100%;display: flex;flex-direction: column; width: 100%;} //background-color: darken($color: $white, $amount: 3%);
.receipt-text{font-weight: 900; font-size: 20px; color: #64769a}
.receipt-button{text-transform: none !important}
.glc1 {fill: $sky;}
.glc2 {fill:#084975;}

.dark{
	.glc2 {fill:$white;}
}
// @import 'scss/layout.scss';
@import 'scss/global.scss';
@media screen and (max-width: 600px) {
	.clear{
		&10{height: 0.625rem;}
		&20{height: 1.25rem;}
		&30{height: 1.875rem;}
		&40{height: 2.5rem;}
		&60{height: 3.125rem;}
	}
}
.card-steps-center, .idvc-version-plank-container, #message-box-container, #back-button, .test-uploader__icons-container{
	display: none!important;
}

.idvc .main-screen-container{
	display: flex;
	justify-content: center;
	width: 100%;
}

.idvc .steps-list-screen{
	margin: 0 auto;
}
//.vc-loading-mask.vc-loading-mask--enabled{
//	display: none !important;
//}
//main-screen-loading

//vc-loading-mask vc-loading-mask--enabled
